
import PersonLink from "~/components/EntityLink/components/PersonLink.vue";
import PropertyLink from "~/components/EntityLink/PropertyLink.vue";
import CompanyLink from "~/components/EntityLink/components/CompanyLink.vue";

export default {
  name: "SearchResultTableCellPerson",

  components: {
    PersonLink,
    PropertyLink,
    CompanyLink,
  },

  inheritAttrs: false,

  props: {

    /**
     * id of the entity
     */
    id: {
      type: [String, Number],
      default: null
    },

    /**
     * type of the entity
     */
    type: {
      type: String,
      required: true
    },

    /**
     * name or address of the entity
     */
    displayName: {
      type: String,
      required: true
    },
  }
};
